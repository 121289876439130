import { formatPrice } from "@/helpers/transformation/price";

export function calculateBuildPriceOrPackagePriceTotal(
  homePrice: number,
  facadePrice: number,
  siteCosts: number,
  landPrice?: number
): string {
  const homePriceNum = isNaN(homePrice) ? 0 : Number(homePrice);
  const facadePriceNum = isNaN(facadePrice) ? 0 : Number(facadePrice);
  const siteCostsNum = isNaN(siteCosts) ? 0 : Number(siteCosts);
  const additionalCostsNum = landPrice !== undefined && !isNaN(landPrice) ? Number(landPrice) : 0;

  const totalPrice = homePriceNum + facadePriceNum + siteCostsNum + additionalCostsNum;

  return formatPrice(totalPrice);
}